import React from "react"
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="About" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h1" dividing textAlign="left">
              <Icon name="question" />
              <Header.Content>
                Contact Us
                <Header.Subheader>
                  How can I contact the contributors?
                </Header.Subheader>
              </Header.Content>
            </Header>

            <Container text>
              We are welcome to answer any question or request.
            </Container>
            <Container text>
              You can reach us either by email or you can find us on our Discord community.
            </Container>

            <Button 
              style={{ width: "450px", margin: "2px", marginTop: "20px"}}
              as={"a"}
              href="mailto:contact@progmiscon.org"
              target="_blank"
              content="Send us an email" 
              icon="mail"
              labelPosition="right"  
            />

            <Grid.Column style={{ margin: "2px"}}>
              <iframe 
                src="https://discord.com/widget?id=826810731504205864&theme=dark" 
                width="450"
                height="300"
                allowtransparency="true"
                frameBorder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
              </iframe>
            </Grid.Column>

            <Header as="h1" dividing textAlign="left">
              <Icon name="world" />
              <Header.Content>
                Social Media
                <Header.Subheader>
                  Follow us on the following social media
                </Header.Subheader>
              </Header.Content>
            </Header>

            <Button 
              style={{ width: "220px", margin: "2px"}}
              as={"a"}
              href="https://twitter.com/progMiscon"
              target="_blank"
              color="twitter"
              animated='vertical'
            >
              <Button.Content hidden>@progMiscon</Button.Content>
              <Button.Content visible>
                <Icon name='twitter' />
              </Button.Content>
            </Button>

            <Button 
              style={{ width: "220px", margin: "2px"}}
              as={"a"}
              href="https://www.youtube.com/channel/UCxqznaqI0uWLgACSD0OuRwg"
              target="_blank"
              color="youtube"
              animated='vertical'
            >
              <Button.Content hidden>LuCE Research</Button.Content>
              <Button.Content visible>
                <Icon name='youtube' />
              </Button.Content>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Layout>
)

export default ContactPage
